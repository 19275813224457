<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="会员权益：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题别名：">
        <el-input style="max-width: 400px;" v-model="form.alias" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="填写内容：">
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="form.cyc_content"
          style="max-width: 540px"
        />
      </el-form-item>
    </el-form>
    <FixedActionBar :z-index="2">
      <el-button  @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>

    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
        v-model="showVideoList"
        type="video"
        multiple
        @select="updateVideos"
    />
    <media-selector
        v-model="showAudioList"
        type="audio"
        multiple
        @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import Tinymce from '@/base/components/Editor/Tinymce'
import CycIntroducePage from '../../mixins/CycIntroducePage'
import ImportHtmlCode from '../../../common/components/ImportHtmlCode'
import ImportArticle from '../../../../base/components/Editor/ImportArticle'
import MediaSelector from '../../../common/components/MediaSelector'
export default {
  mixins: [CycIntroducePage],
  components: {MediaSelector, ImportArticle, ImportHtmlCode, FixedActionBar, Tinymce },
  model: {
    prop: "previewForm",
    event: "setPreviewForm",
  },
  props: {
    previewForm:Object,
    formData: Object,
  },
  data() {
    return {
      key:'rights',
      loading: true,
      rules: {},

      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    }
  },
  computed: {
    form:{
      get(){
        console.log(this.$props);
        return this.$props.previewForm[this.key]
      },
      set(val){
        let formData = {...this.$props.previewForm}
        formData[this.key] = val
        this.$emit('setPreviewForm',formData)
      }
    }
  },
  methods: {
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
            'mceReplaceContent',
            false,
            `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
            'mceReplaceContent',
            false,
            `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
            // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
            'mceReplaceContent',
            false,
            `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      // e: {account, content, cover_image, title}

      const newContent = this.form.cyc_content + '<p><br/></p>' + e.content
      this.$set(this.form, 'cyc_content', newContent)
      this.$refs.editor.$emit('change', newContent)
    },
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    cancel(){
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
    /**
     * 富文本编辑器自定义工具栏按钮点击事件
     * @param {Object} e
     */
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
  },
  watch: {
    '$props.formData.rights': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style></style>
